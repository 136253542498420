<template>
  <div class="login-wrapper">
    <div>
      <h1 class="card-title fw-bold mb-1 auth-main-title">
        {{ $t('auth.register.title') }}
      </h1>
      <p class="card-text mb-2">
        {{ $t('auth.register.subtitle') }}
      </p>
    </div>

    <!-- Error Message -->
    <b-alert
      v-if="error"
      variant="danger"
      show
      class="text-center w-100 rounded pb-2 pt-2"
    >
      {{ $t(error) }}
    </b-alert>

    <!-- Registration Form -->
    <b-form 
      v-if="registerToken"
      class="auth-login-form mt-2" 
      @submit.prevent="handleSubmit"
    >
      <!-- Name -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.name.label') }}
        </label>
        <b-form-input
          id="register-name"
          v-model="formData.name"
          type="text"
          name="name"
          :placeholder="$t('auth.register.form.name.placeholder')"
          required
          :disabled="loading"
        />
      </div>

      <!-- Surname -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.surname.label') }}
        </label>
        <b-form-input
          id="register-surname"
          v-model="formData.surname"
          type="text"
          name="surname"
          :placeholder="$t('auth.register.form.surname.placeholder')"
          required
          :disabled="loading"
        />
      </div>

      <!-- Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.password.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="register-password"
            v-model="formData.password"
            :type="passwordFieldType"
            name="password"
            :placeholder="$t('auth.register.form.password.placeholder')"
            class="form-control-merge"
            required
            :disabled="loading"
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
        <small v-if="showPasswordAlert" class="text-danger">
          {{ $t('auth.register.form.password.min-characters') }}
        </small>
      </div>

      <!-- Repeat Password -->
      <div class="mb-1">
        <label class="form-label">
          {{ $t('auth.register.form.password-confirm.label') }}
        </label>
        <div class="input-group input-group-merge">
          <b-form-input
            id="register-password-confirm"
            v-model="formData.repeatPassword"
            :type="passwordFieldType"
            name="repeatPassword"
            :placeholder="$t('auth.register.form.password-confirm.placeholder')"
            class="form-control-merge"
            required
            :disabled="loading"
          />
          <div class="input-group-append">
            <div class="input-group-text cursor-pointer" @click="togglePasswordVisibility">
              <feather-icon
                :icon="passwordToggleIcon"
                size="16"
                class="toggle-password-icon"
              />
            </div>
          </div>
        </div>
        <small v-if="showRepeatPasswordAlert" class="text-danger">
          {{ $t('auth.register.form.password.min-characters') }}
        </small>
      </div>

      <div v-if="showLegalCheckboxes">
        <div v-if="isInvited">
          <p>
            {{ $t('auth.register.form.invited-text') }}
          </p>
        </div>

          <!-- Privacy -->
          <div class="mb-1">
            <b-form-checkbox
            v-model="formData.privacyAccepted"
            name="privacy"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.privacy.text1') }}
            <b-link :href="privacyPolicyUrl" target="_blank">
              {{ isNexos ? $t('auth.signup.form.privacy.link2') : $t('auth.signup.form.privacy.link') }}
            </b-link>
          </b-form-checkbox>
        </div>

        <!-- Terms -->
        <div class="mb-1">
          <b-form-checkbox
            v-model="formData.termsAccepted"
            name="terms"
            :disabled="loading"
          >
            {{ $t('auth.signup.form.terms.text1') }}
            <b-link :href="termsAndConditionsUrl" target="_blank">
              {{ $t('auth.signup.form.terms.link') }}
            </b-link>
          </b-form-checkbox>
        </div>
      </div>

      <!-- Legal -->
      <div v-if="isNexos" class="mb-1">
        {{ $t('auth.signup.legal.text1') }}
        <b-link :href="bancolombiaLegalUrl" target="_blank">
          {{ $t('auth.signup.legal.link') }}
        </b-link>
      </div>

      <!-- Submit -->
      <div class="auth-btn-container d-flex justify-content-center">
        <b-button
          type="submit"
          variant="primary"
          class="auth-btn auth-btn-primary mt-2 mb-2"
          :disabled="loading || isDisabled"
        >
          {{ $t('auth.register.form.action.submit') }}
        </b-button>
      </div>
    </b-form>

    <div v-else>

    </div>

    <!-- Login Link -->
    <p class="text-center mt-2">
      <span>{{ $t('auth.register.login.text') }}</span>
      <b-link class="auth-link" :to="{ name: 'auth-login' }">
        {{ $t('auth.register.login.link') }}
      </b-link>
    </p>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Vue from 'vue'

export default {
  name: 'Register',

  inject: ['authApiUrl'],

  mixins: [togglePasswordVisibility],

  data() {
    return {
      formData: {
        name: '',
        surname: '',
        password: '',
        repeatPassword: '',
        privacyAccepted: false,
        termsAccepted: false
      },
      loading: false,
      error: null,
      showPasswordAlert: false,
      showRepeatPasswordAlert: false,
      isDisabled: true
    }
  },

  computed: {
    collective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.collective;
    },
    communitySlug() {
      return this.$route.params.communityId
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isNexos() {
      return process.env.VUE_APP_CUSTOMER === 'nexos' || this.mainSpace?.key === 'f3166db0-b635-11ef-805d-f79ce25412de';
    },
    bancolombiaLegalUrl() {
      return 'https://www.bancolombia.com/personas/documentos-legales/proteccion-datos/bancolombia-sa'
    },
    registerToken() {
      return this.$route.query.token
    },
    isInvited() {
      return this.$route.query.invited
    },
    showLegalCheckboxes() {
      // show the checkboxes if the user was invited
      return this.isInvited;  //TODO: what happens if the user deletes the "invited" query in the link?
    }
  },

  watch: {
    'formData.password'(newVal) {
      this.showPasswordAlert = newVal.length > 0 && newVal.length < 6
      this.validateForm()
    },
    'formData.repeatPassword'(newVal) {
      this.showRepeatPasswordAlert = newVal.length > 0 && newVal.length < 6
      this.validateForm()
    }
  },

  created() {
    console.log('Register created')
    if(!this.registerToken){
      this.error = this.$t('auth.register.error.no-token');
    }
  },

  methods: {
    validateForm() {
      this.isDisabled = !(
        this.formData.password.length >= 6 &&
        this.formData.repeatPassword.length >= 6 &&
        this.formData.name &&
        this.formData.surname
      )
    },

    async handleSubmit() {
      if (this.formData.password !== this.formData.repeatPassword) {
        this.error = 'auth.register.error.password-mismatch'
        return
      }

      if(this.showLegalCheckboxes && (!this.formData.privacyAccepted || !this.formData.termsAccepted)){
        this.error = 'auth.register.error.privacy-terms-not-accepted'
        return
      }

      const endpoint = this.authApiUrl + 'register';

      try {
        this.loading = true
        this.error = null

        const response = await fetch(
          endpoint,
          {
            method: 'POST',
            body: JSON.stringify({
              ...this.formData,
              communitySlug: this.communitySlug,
              token: this.$route.query.token,
              invited: this.$route.query.invited ? Number(this.$route.query.invited) : undefined,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              defaultLanguage: this.$i18n.locale
            }),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        const data = await response.json()

        if (!response.ok) {
          throw new Error(data.message || 'auth.register.error.general')
        }

        const date = new Date(
          new Date().setFullYear(new Date().getFullYear() + 1)
        )

        // Set cookies for both domains
        Vue.$cookies.set('accessToken', data.token, {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN_1 || 'localhost',
          expires: date
        })

        Vue.$cookies.set('accessToken', data.token, {
          path: '/',
          domain: process.env.VUE_APP_DOMAIN_2 || 'localhost',
          expires: date
        })

        // Redirect to app
        const destinationURL = process.env.VUE_APP_APP_URL_1 + '/' + this.communitySlug;
        console.log('destinationURL', destinationURL)

        window.location.href = destinationURL;

      } catch (error) {
        console.error('Registration error:', error)
        this.error = 'auth.register.error.general'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input-group-merge {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .form-control {
    &:not(:last-child) {
      border-right: 0;
      padding-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-left: 0;
      padding-left: 0;
    }
  }

  .input-group-append {
    display: flex;
    
    .input-group-text {
      background-color: #f8f8f8;
      border: 1px solid #d8d6de;
      border-left: 0;
      padding: 0.571rem 1rem;
      display: flex;
      align-items: center;
      border-top-right-radius: 0.357rem;
      border-bottom-right-radius: 0.357rem;
      margin-left: -1px;
      
      &:hover {
        background-color: #efefef;
      }
    }
  }
}

.toggle-password-icon {
  color: #6e6b7b;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
